import { NodeExpandOutlined } from "@ant-design/icons";
import { Alert, Row, message, Table } from "antd";
import Search from "antd/lib/input/Search";
import BugReason from "components/BugReason/BugReason";
import ChangeCardStatusButton from "components/ChangeCardStatusButton";
import EditCardButton from "components/EditCardButton";
import ModalButton from "components/ModalButton";
import PageHeading from "components/PageHeading/PageHeading";
// import { has } from "lodash";
import React, { useMemo, useRef, useState } from "react";
import Helmet from "react-helmet";
import { RouteProps } from "react-router-dom";
import axiosClient from "utils/axiosClient";
// import fetchRouteData from "utils/fetchRouteData";
import { highlightMatches } from "utils/highlightMatches";
import parseVehicleRegNumber from "utils/parseVehicleRegNumber";

interface Props extends RouteProps {}

export const MagazynPage: React.FC<Props> = () => {
  const [cards, setCards] = useState<Card[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [selectedRows, setSelectedRows] = useState<Card[]>([]);

  const highlightHandler = (value: any) => (
    <span style={{ whiteSpace: "pre-wrap", maxWidth: 400 }}>{highlightMatches(value, searchInput)}</span>
  );

  const fetchCards = useRef(() => {});

  const ChangeMultipleStatusesButton = () =>
    selectedRows.length > 0 ? (
      <ModalButton
        buttonProps={{
          icon: <NodeExpandOutlined />,
          type: "primary",
          style: { marginLeft: 10 },
        }}
        buttonTitle="Zmień statusy kart"
        method="POST"
        modalTitle="Zmiana statusów kart"
        endpoint="cards/changestatus"
        okText="Aktualizuj"
        parseValues={(values) => ({
          cards: selectedRows.map(({ card: fullCard, wasteMass }) => ({
            ...fullCard,
            wasteMass,
          })),
        })}
        tooltipText="Zmień statusy zaznaczonych w tabeli kart"
        successCallback={() => {
          setCards([]);
          setSelectedRows([]);
        }}
        successMessage="Pomyślnie zmieniono statusy kart!"
      >
        {selectedRows.some((c) => c.cardStatus === "Potwierdzenie przejęcia") && (
          <Alert
            style={{ marginBottom: 5 }}
            message={
              <>
                Wszystkie karty o statusie <b>Potwierdzenie przejęcia</b> zmienią status na{" "}
                <b>Potwierdzenie transportu</b>.
              </>
            }
            type="warning"
            showIcon
          />
        )}
        {selectedRows.some((c) => c.cardStatus === "Potwierdzenie wygenerowane") && (
          <Alert
            style={{ marginBottom: 5 }}
            message={
              <>
                Wszystkie karty o statusie <b>Potwierdzenie wygenerowane</b> zmienią status na{" "}
                <b>Potwierdzenie przejęcia</b>.
              </>
            }
            type="warning"
            showIcon
          />
        )}
      </ModalButton>
    ) : null;

  const filteredData = useMemo(
    () =>
      searchInput
        ? cards.filter((item) =>
            Object.values(item).some((value) => String(value).toLowerCase().includes(searchInput.toLowerCase()))
          )
        : cards,
    [cards, searchInput]
  );

  return (
    <div>
      <Helmet>
        <title>Magazyn | Utylimed</title>
      </Helmet>
      <PageHeading
        title="Magazyn – karty przejęte"
        onSearch={(e) => {
          e.persist();
          setSearchInput(e.currentTarget.value);
        }}
      >
        <Search
          placeholder="Numer rejestracyjny..."
          style={{ marginLeft: 10, maxWidth: 300 }}
          enterButton="Pobierz karty"
          onSearch={(value) => {
            if (!value) {
              message.warning("Nie znaleziono pasujących kart.");
              return;
            }
            setCards([]);
            const fetchData = async () => {
              let hasNextPage = true;
              let nextPage = 1;
              let isData = false;
              let dataLength = 0;
              setLoading(true);
              while (hasNextPage) {
                try {
                  const { data }: any = await axiosClient(
                    `storage/cards/receiver/${value}${hasNextPage ? "?page=" + nextPage : ""}`
                  );
                  setCards((prev) => [...prev, ...data.cards]);
                  hasNextPage = data.hasNextPage;
                  nextPage++;
                  setLoading(false);
                  if (data.cards.length > 0) {
                    isData = true;
                    dataLength = dataLength + data.cards.length;
                  }
                } catch (e) {
                  message.error("Coś poszło nie tak, spróbuj ponownie później.");
                  hasNextPage = false;
                  setLoading(false);
                  return;
                }
              }
              isData
                ? message.success(`Liczba pobranych kart: ${dataLength}`)
                : message.warning("Nie znaleziono pasujących kart.");
            };
            fetchCards.current = fetchData;
            fetchData();
            setSelectedRows([]);
          }}
        />
        <ChangeMultipleStatusesButton />
      </PageHeading>
      <Table
        dataSource={filteredData}
        loading={loading}
        rowKey={(record) => record.card.cardNumber!}
        rowSelection={{
          onChange: (keys, rows) => {
            setSelectedRows(rows.filter((c) => !!c));
          },
          selectedRowKeys: selectedRows.map((row) => row.card.cardNumber!),
        }}
        scroll={{ x: true }}
        columns={[
          {
            key: "bugReason",
            title: "Uwagi",
            align: "center",
            render: (value, item) => <BugReason bugReason={item.bugReason} />,
          },
          {
            dataIndex: "senderName",
            title: "Nazwa firmy",
            render: highlightHandler,
          },
          {
            dataIndex: "address",
            title: "Adres",
            render: highlightHandler,
          },
          {
            dataIndex: "wasteCode",
            title: "Numer odpadu",
            render: highlightHandler,
          },
          {
            dataIndex: "wasteMass",
            title: "Masa",
            render: highlightHandler,
          },
          {
            dataIndex: "cardStatus",
            title: "Kod",
            render: highlightHandler,
          },
          {
            dataIndex: "vehicleRegNumber",
            title: "Numer rejestracyjny",
            render: (value) => highlightMatches(parseVehicleRegNumber(value), searchInput),
          },
          {
            dataIndex: "created_at",
            title: "Data utworzenia",
            sorter: (a, b) => a.created_at - b.created_at,
            defaultSortOrder: "descend",
            showSorterTooltip: false,
            render: (value) => new Date(value).toLocaleString("pl-PL"),
          },
          {
            dataIndex: "plannedTransportTime",
            sorter: (a, b) => {
              const dateA = new Date(a.plannedTransportTime);
              const dateB = new Date(b.plannedTransportTime);
              return dateA > dateB ? 1 : dateA === dateB ? 0 : -1;
            },
            title: "Planowana data",
            render: (value) => new Date(value).toLocaleString("pl-PL"),
          },
          {
            key: "akcje",
            title: "Akcje",
            fixed: "right",
            render: (value, item) => (
              <Row align="middle" justify="end" style={{ flexWrap: "nowrap" }}>
                <ChangeCardStatusButton card={item} isReceiver />
                <EditCardButton card={item} fetchCards={fetchCards.current} />
              </Row>
            ),
          },
        ]}
      />
    </div>
  );
};

export default MagazynPage;
