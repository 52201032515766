// insert user pool config here
export const awsconfig =
  process.env.NODE_ENV === "development"
    ? {
      aws_project_region: "eu-central-1",
      aws_cognito_region: "eu-central-1",
      aws_user_pools_id: "eu-central-1_rlaTHnjci",
      aws_user_pools_web_client_id: "375udmckq632sap4g0nh9vkuq0",
    }
    : process.env.NODE_ENV === "production"
      ? {
        aws_project_region: "eu-central-1",
        aws_cognito_region: "eu-central-1",
        aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOL_ID,
        aws_user_pools_web_client_id:
          process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
      }
      : {
        aws_project_region: "eu-central-1",
        aws_cognito_region: "eu-central-1",
        aws_user_pools_id: "",
        aws_user_pools_web_client_id: "",
      }

// REST endpoint
export const awsendpoint =
  process.env.NODE_ENV === "development"
    ? "https://xku0fdncyl.execute-api.eu-central-1.amazonaws.com/dev"
    : process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_ENDPOINT
      : ""

export default awsconfig